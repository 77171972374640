<template>
  <v-row>
    <v-col>
      <v-btn
        :disabled="activities.length > 0"
        outlined
        block
        class="mb-4"
        color="green"
        :to="{ name: 'QualityEditActivity', params: { id: 0 } }"
        ><v-icon left>mdi-plus</v-icon> Q-Arbeit erfassen</v-btn
      >
      <v-card
        v-for="activity in activities"
        :key="'activity' + activity.id"
        class="mb-4"
      >
        <v-system-bar>Q-Arbeit</v-system-bar>
        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-calendar </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                activity.period.description
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                formatDatespan(
                  activity.period.startDate,
                  activity.period.endDate
                )
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <PersonItemBasic :value="activity.person" />
          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-instrument-triangle </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Instrument</v-list-item-title>
              <v-list-item-subtitle>{{
                activity.instrument.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-text>{{ activity.description }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :to="{ name: 'QualityEditActivity', params: { id: activity.id } }"
            >bearbeiten</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-col>
    <v-col>
      <v-btn
        outlined
        block
        class="mb-4"
        color="green"
        :to="{ name: 'QualityEditFeedback', params: { id: 0 } }"
        ><v-icon left>mdi-plus</v-icon> Feedback erfassen</v-btn
      >

      <v-card
        v-for="feedback in feedbacks"
        :key="'feedback' + feedback.id"
        class="mb-4"
      >
        <v-system-bar>Feedback {{ feedback.description }}</v-system-bar>

        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-calendar </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                formatDate(feedback.date, true)
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                feedback.period.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <PersonItemBasic :value="feedback.person" />
          <Course :value="feedback.course" avatar="mdi-school" />

          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-account-multiple </v-icon>
            </v-list-item-avatar>

            <v-list-item-subtitle>
              <v-tooltip
                top
                v-for="schoolClass in feedback.schoolClasses"
                :key="schoolClass.id + '_' + schoolClass.code"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip small dark v-bind="attrs" v-on="on">
                    <v-progress-circular
                      color="success"
                      size="16"
                      width="2"
                      :value="schoolClass.percentage"
                      class="mr-2"
                    ></v-progress-circular>
                    {{ schoolClass.code }}
                  </v-chip>
                </template>
                <span
                  >{{ schoolClass.code }}: {{ schoolClass.percentage }} %</span
                >
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-avatar>
              <v-icon> mdi-tools </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Methode</v-list-item-title>
              <v-list-item-subtitle>{{
                feedback.method.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="feedback.closed">
            <v-list-item-avatar>
              <v-icon> mdi-check-circle </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>abgeschlossen</v-list-item-title>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-avatar>
              <v-icon> mdi-circle </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>nicht abgeschlossen</v-list-item-title>
            <v-list-item-action-text
              ><v-btn
                text
                class="primary"
                :loading="feedback.closing"
                small
                @click="closeFeedback(feedback)"
                >abschliessen</v-btn
              ></v-list-item-action-text
            >
          </v-list-item>
        </v-list>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :to="{ name: 'QualityEditFeedback', params: { id: feedback.id } }"
          >
            bearbeiten</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-col>
    <router-view></router-view>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";
import { formatDate, formatDatespan } from "common/utils/date.js";
import Course from "@/components/Course.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default defineComponent({
  components: { Course, PersonItemBasic },
  props: ["search"],
  data() {
    return {
      activities: [],
      feedbacks: [],
      loading: false,
    };
  },
  methods: {
    async closeFeedback(feedback) {
      feedback.closing = true;
      this.$forceUpdate();
      await this.apiPatch({
        resource: "quality/feedback",
        id: feedback.id,
        key: "closed",
        value: true,
      });
      this.loading = true;
      this.feedbacks = await this.apiList({
        resource: `quality/feedback?person=${this.$_profilePerson.id}`,
      });
      this.loading = false;
    },
    formatDate,
    formatDatespan,
    async fetchData() {
      this.loading = true;
      this.feedbacks = await this.apiList({
        resource: "quality/feedback",
        query: `person=${this.$_profilePerson.id}`,
      });
      this.activities = await this.apiList({
        resource: "quality/activity",
        query: `person=${this.$_profilePerson.id}`,
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === "QualityEditFeedback") {
      this.loading = true;
      this.feedbacks = await this.apiList({
        resource: "quality/feedback",
        query: `person=${this.$_profilePerson.id}`,
      });
      this.loading = false;
    }
    if (from.name === "QualityEditActivity") {
      this.loading = true;
      this.activities = await this.apiList({
        resource: "quality/activity",
        query: `person=${this.$_profilePerson.id}`,
      });
      this.loading = false;
    }
    next();
  },
});
</script>
